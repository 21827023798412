<template>
	<div
		id="home"
		:style="{
			paddingTop: this.$vuetify.breakpoint.smAndDown ? this.appBarSizes.mobileHeight : this.appBarSizes.desktopHeight,
		}"
	>
		<v-layout wrap fill-height>
			<v-flex xs12 sm12 md12>
				<carousel :images="getSite.banners" :options="bannerOptions" />
			</v-flex>
		</v-layout>
		<div fluid v-if="getSite">
			<Model />
		</div>
	</div>
</template>

<script>
import Model from '@/components/enduser/dark-one/Model.vue';
import { mapGetters } from 'vuex';
import Carousel from '../../../../components/_shared/Carousel.vue';

export default {
	name: 'Home',
	components: {
		Model,
		Carousel,
	},
	data() {
		return {
			carousel: 0,
			bannerOptions: {
				dots: true,
				arrows: false,
				autoPlaySlideInterval: 5000,
				showIndicatorOutside: true,
			},
		};
	},
	computed: {
		...mapGetters(['getSite']),
		isTopBarShow() {
			return this.getSite?.showContactInfoHeader;
		},
		appBarSizes() {
			return this.isTopBarShow
				? {
						mobileHeight: '110px',
						desktopHeight: '120px',
						maxHeight: '120px',
				  }
				: {
						mobileHeight: '85px',
						desktopHeight: '95px',
						maxHeight: '100px',
				  };
		},
	},
	methods: {},
};
</script>

<style lang="scss" scoped>
#home {
	.banner-text-bg {
		background-color: rgba(41, 41, 41, 0.2);
		backdrop-filter: blur(5px);
		border-radius: 5px;
		overflow: hidden;
	}

	padding-bottom: 0px;
	background-color: #f1f1f1;
	position: relative;
}
</style>
