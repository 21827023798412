<template>
	<div id="home" :class="{ 'overflow-visible mb-7': $vuetify.breakpoint.mdAndDown, 'listing-has': hasListing }">
		<v-layout wrap fill-height>
			<v-flex xs12 sm12 md12 lg6>
				<v-carousel
					v-model="carousel"
					hide-delimiter-background
					continuous
					cycle
					:hide-delimiters="isDelimiterHide"
					:height="$vuetify.breakpoint.mdAndDown ? 325 : 834"
					:show-arrows="false"
					interval="5000"
					width="100%"
				>
					<v-carousel-item class="carousel-item" v-for="(banner, i) in getSite.banners" :key="i" :src="banner.file">
						<v-sheet color="transparent" width="100%" height="100%">
							<v-row no-gutters class="fill-height" align-content="center" align="center" justify="end">
								<a v-if="banner.link !== null" :href="banner.link" target="_blank"></a>
								<v-col
									sm="12"
									md="6"
									offset-md="6"
									class="banner-text-bg pa-5"
									:style="{ color: banner.color }"
									:class="$vuetify.breakpoint.smAndDown ? 'mx-5 mt-12' : 'mx-10 mt-5'"
									v-if="bannerTextContainerIsShowing(banner)"
								>
									<div class="font-weight-bold" :class="$vuetify.breakpoint.smAndDown ? 'text-h5' : 'text-h3'">
										{{ banner.title }}
									</div>
									<div class="text-subtitle-1">{{ banner.subTitle }}</div>
								</v-col>
							</v-row>
						</v-sheet>
					</v-carousel-item>
				</v-carousel>
			</v-flex>

			<v-flex xs12 sm12 md12 lg6 align-self-center>
				<div>
					<model justify="start" :class="{ 'px-5 mt-5': hasListing, 'px-10': $vuetify.breakpoint.mdAndUp }" />
					<div v-if="$vuetify.breakpoint.lgAndUp && !hasListing" class="text-body-2 px-10 py-2">
						Aracınızı ücretsiz değerleyin, Anında satın.
					</div>
					<bottom-line class="hidden-md-and-down" />
				</div>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
import Model from '@/components/enduser/wizard/Model.vue';
import { mapGetters } from 'vuex';
import BottomLine from '@/components/enduser/blue-one/bottomLine.vue';

export default {
	name: 'Home',
	components: {
		Model,
		BottomLine,
	},
	data() {
		return {
			carousel: 0,
		};
	},
	computed: {
		...mapGetters(['getSite', 'hasListing']),
	},
	methods: {
		bannerTextContainerIsShowing(banner) {
			if (banner.title || banner.subTitle) {
				return true;
			}
			return false;
		},
	},
};
</script>

<style lang="scss" scoped>
::v-deep {
	.carousel-item {
		.v-image__image--cover {
			// background-size: 100% 100% !important;
		}

		a {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
		}
	}
}

#home {
	height: 834px;
	position: relative;
	overflow: hidden;

	&.overflow-visible {
		height: 370px !important;

		&.listing-has {
			height: 625px !important;
		}
	}

	.banner-text-bg {
		background-color: rgba(41, 41, 41, 0.2);
		backdrop-filter: blur(5px);
		border-radius: 5px;
		overflow: hidden;
	}
}
</style>
